import * as React from "react"
import { navigate, Link } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Input from "../components/UI/Form/Input"
import Select from "../components/UI/Form/Select"
import Checkbox from "../components/UI/Form/Checkbox"
import * as yup from "yup"
import { useFormik } from "formik"

interface DemoData {
  fullName: string
  workEmail: string
  jobTitle: string
  organizationName: string
  companySize: string
  phone: string
  keepUpdated: boolean
}

const initialDemoFormData: DemoData = {
  fullName: "",
  workEmail: "",
  jobTitle: "",
  organizationName: "",
  companySize: "",
  phone: "",
  keepUpdated: false,
}

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)

const validPhoneRegex = RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
)

const validationSchema = yup.object({
  fullName: yup.string().required("Full Name is a required field."),
  workEmail: yup
    .string()
    .email("Please enter a valid E-mail address.")
    .required("E-mail is a required field."),
  jobTitle: yup.string().required("Job Title is a required field."),
  organizationName: yup
    .string()
    .required("Organization Name is a required field."),
  companySize: yup
    .string()
    .matches(RegExp(/\bEmployees\b/), "Invalid option selected.")
    .required("Company Size is a required field."),
  phone: yup
    .string()
    .matches(validPhoneRegex, "Please enter a valid Phone number."),
  keepUpdated: yup.boolean(),
})

const RequestADemo = () => {
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: initialDemoFormData,
      validationSchema,
      onSubmit(values) {
        //Make a E-mail Service API call to send email.
        console.log(values)
        navigate("/request-a-demo/thanks")
      },
    })

  const companySizeList = [
    { id: 0, value: "Select Company Size", selected: false },
    { id: 1, value: "0-100 Employees", selected: false },
    { id: 2, value: "101-500 Employees", selected: false },
    { id: 3, value: "501-1000 Employees", selected: false },
    { id: 4, value: "1001-10,000 Employees", selected: false },
    { id: 5, value: "10,0001+ Employees", selected: false },
  ]

  return (
    <Layout>
      <section className="text-gray-600 body-font relative">
        <Seo title="Sein Tech | Request a Demo" />
        <div className="container px-5 py-24 mx-auto">
          <div className="md:w-3/4 lg:w-2/3 xl:w-1/3 mx-auto">
            <form
              className="bg-white px-6 py-8 rounded-md shadow-2xl flex flex-col -m-2"
              onSubmit={handleSubmit}
            >
              <h1 className="text-center title-font my-0">
                Request for a demo.
              </h1>
              <p className="text-center text-sm">
                Powering Real Estate with technology
              </p>
              <Input
                label="Full Name"
                name="fullName"
                value={values.fullName}
                placeholder="Full Name"
                type="text"
                error={
                  errors.fullName && touched.fullName ? errors.fullName : ""
                }
                required={true}
                changed={handleChange}
                blurChanged={handleBlur}
              />
              <Input
                label="Work E-mail"
                name="workEmail"
                value={values.workEmail}
                placeholder="Work E-mail"
                type="text"
                error={
                  errors.workEmail && touched.workEmail ? errors.workEmail : ""
                }
                required={true}
                changed={handleChange}
                blurChanged={handleBlur}
              />
              <Input
                label="Job Title"
                name="jobTitle"
                value={values.jobTitle}
                placeholder="Job Title"
                type="text"
                error={
                  errors.jobTitle && touched.jobTitle ? errors.jobTitle : ""
                }
                required={true}
                changed={handleChange}
                blurChanged={handleBlur}
              />

              <Input
                label="Organization Name"
                name="organizationName"
                value={values.organizationName}
                placeholder="Organization Name"
                type="text"
                error={
                  errors.organizationName && touched.organizationName
                    ? errors.organizationName
                    : ""
                }
                required={true}
                changed={handleChange}
                blurChanged={handleBlur}
              />
              <Select
                label="Company Size"
                value={values.companySize}
                name="companySize"
                error={
                  errors.companySize && touched.organizationName
                    ? errors.companySize
                    : ""
                }
                required={true}
                options={companySizeList}
                changed={handleChange}
              />
              <Input
                label="Phone (Optional)"
                name="phone"
                value={values.phone}
                placeholder="123-456-7890"
                type="text"
                error={errors.phone && touched.phone ? errors.phone : ""}
                required={false}
                changed={handleChange}
                blurChanged={handleBlur}
              />
              <div className="text-sm mb-5">
                <Checkbox
                  checkboxText="Yes, please keep me updated on Sein Tech Partners news,
                  events, webcast and special offers."
                  changed={handleChange}
                  name="keepUpdated"
                />
                <p>
                  By submitting the form, you are agreeing to our{" "}
                  <Link to="/privacy-policy" className="link">
                    privacy policy.
                  </Link>
                </p>
              </div>
              <button className="btn-emerald md:w-1/2" type="submit">
                REQUEST DEMO
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RequestADemo

import React from "react"
import { ChangeEvent } from "react"

const Select: React.FC<{
  label: string | number
  value: string | number
  id?: string
  name?: string
  error: string
  required: boolean
  options: { id: number; value: string; selected: boolean }[]
  changed: (e: ChangeEvent<HTMLSelectElement>) => void
}> = props => (
  <div className="my-2">
    <label
      className={`leading-7 block text-sm font-bold ${
        props.error.length > 0 ? "text-red-500" : "text-gray-700"
      } `}
      htmlFor={props.id}
    >
      {props.label} {props.required && <span className="text-red-600">*</span>}
    </label>
    <select
      className={`${
        props.error.length > 0 ? "form-select input-error" : "form-select input"
      }`}
      name={props.name}
      required={props.required}
      value={props.value}
      onChange={props.changed}
    >
      {props.options.map(option => (
        <option
          key={option.id}
          id={option.id.toString()}
          value={option.value}
          className="py-2 border-b-4 block"
        >
          {option.value}
        </option>
      ))}
    </select>
    {props.error.length > 0 && <p className="error-text">{props.error}</p>}
  </div>
)

export default Select
